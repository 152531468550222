const bg: { [key: string]: string } = {
  'Плати онлайн': 'Плати онлайн',
  'Моля, използвай телефонния номер, с който си се регистрирал, или изтегли ecoints.': 'Моля, използвай телефонния номер, с който си се регистрирал, или изтегли ecoints.',
  'Липсва профил с избрания от теб номер.': 'Липсва профил с избрания от теб номер.',
  'Сканирай и изтегли': 'Сканирай и изтегли',
  'Затвори': 'Затвори',
  'Дигитален портфейл ecoints': 'Дигитален портфейл ecoints',
  'Дигиталният портфейл': 'Дигиталният портфейл',
  'Ползи за теб с дигиталния портфейл на Еконт:': 'Ползи за теб с дигиталния портфейл на Еконт:',
  'плащаш лесно куриерските си услуги, където и да си': 'плащаш лесно куриерските си услуги, където и да си',
  'плащаш с един клик пратките си на място': 'плащаш с един клик пратките си на място',
  'пращаш и получаваш пари от приятел за секунди.': 'пращаш и получаваш пари от приятел за секунди.',
  'Научи повече на': 'Научи повече на',
  'Изникна проблем, моля опитайте пак': 'Изникна проблем, моля опитайте пак',
  'Избери как ще платиш': 'Избери как ще платиш',
  'Предпочитано': 'Предпочитано',
  'Телефонен номер за потвърждение на плащане': 'Телефонен номер за потвърждение на плащане',
  'Ще платя на място чрез QR код': 'Ще платя на място чрез QR код',
  'Банкова карта': 'Банкова карта',
  'Въведи имейл, на който да получиш своя касов бон': 'Въведи имейл, на който да получиш своя касов бон',
  'Въведи имена на картодържателя по банкова карта на латиница': 'Въведи имена на картодържателя по банкова карта на латиница',
  'Въведеното име е непълно.': 'Въведеното име е непълно.',
  'Въведи две имена на картодържателя по банкова карта на латиница': 'Въведи две имена на картодържателя по банкова карта на латиница',
  'Въведените символите трябва да са само на латиница [a-Z]': 'Въведените символите трябва да са само на латиница [a-Z]',
  'В брой': 'В брой',
  'Назад': 'Назад',
  'Потвърди': 'Потвърди',
  'Сигнал': 'Сигнал',
  'Откажи': 'Откажи',
  'Изпрати сигнала': 'Изпрати сигнала',
  'Изпрати': 'Изпрати',
  'Възникна грешка, моля опитайте отново': 'Възникна грешка, моля опитайте отново',
  'Причина за сигнала': 'Причина за сигнала',
  'Минимум 5 символа': 'Минимум 5 символа',
  'Моля, сподели ни повече информация.': 'Моля, сподели ни повече информация.',
  'Минимум 10 символа': 'Минимум 10 символа',
  'Може да качите до 5 снимки/видеа.': 'Може да качите до 5 снимки/видеа.',
  'Изтрий': 'Изтрий',
  'Добави снимка': 'Добави снимка',
  'Прикачи файл': 'Прикачи файл',
  'До 5 снимки/видеа': 'До 5 снимки/видеа',
  'Успешно изпрати сигнал': 'Успешно изпрати сигнал',
  'Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш по банков път?': 'Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш по банков път?',
  'Твоят избор за бъдещи плащания е ecoints. Желаеш ли да го промениш и плащаш по банков път?': 'Твоят избор за бъдещи плащания е ecoints. Желаеш ли да го промениш и плащаш по банков път?',
  'Избери по банков път за бъдещи плащания.': 'Избери по банков път за бъдещи плащания.',
  'Плащането е неуспешно': 'Плащането е неуспешно',
  'Избери друг начин на плащане': 'Избери друг начин на плащане',
  'Успешно плати с банкова карта': 'Успешно плати с банкова карта',
  'Ще получиш електронен касов бон на имейла си.': 'Ще получиш електронен касов бон на имейла си.',
  'Номер на пратка:': 'Номер на пратка:',
  'Пратка': 'Пратка',
  'Промени всяко условие на доставката с': 'Промени всяко условие на доставката с',
  'услугата Разпореждане': 'услугата Разпореждане',
  'Заяви куриер': 'Заяви куриер',
  'Към начало': 'Към начало',
  'Твоят избор за бъдещи плащания е ecoints. Желаеш ли да го промениш и плащаш в брой?': 'Твоят избор за бъдещи плащания е ecoints. Желаеш ли да го промениш и плащаш в брой?',
  'Твоят избор за бъдещи плащания е по банков път. Желаеш ли да го промениш и плащаш в брой?': 'Твоят избор за бъдещи плащания е по банков път. Желаеш ли да го промениш и плащаш в брой?',
  'Избери в брой за бъдещи плащания.': 'Избери в брой за бъдещи плащания.',
  'Успешно заяви плащане в брой': 'Успешно заяви плащане в брой',
  'Ще получиш електронен касов бон на имейла си, когато платиш.': 'Ще получиш електронен касов бон на имейла си, когато платиш.',
  'В брой при изпращане': 'В брой при изпращане',
  'Изпратена е нотификация': 'Изпратена е нотификация',
  'Потвърди трансакцията в приложение ecoints.': 'Потвърди трансакцията в приложение ecoints.',
  'Успешно заяви плащане в ecoints': 'Успешно заяви плащане в ecoints',
  'Нужно е само да го потвърдиш.': 'Нужно е само да го потвърдиш.',
  'Не получаваш известие?': 'Не получаваш известие?',
  'Липсва известие на екрана ти?': 'Липсва известие на екрана ти?',
  'Можеш да подадеш сигнал за нередност.': 'Можеш да подадеш сигнал за нередност.',
  'Изпрати известието отново': 'Изпрати известието отново',
  'Опитай отново': 'Опитай отново',
  'Ще платя по друг начин': 'Ще платя по друг начин',
  'Изпрати сигнал': 'Изпрати сигнал',
  'Може да откажеш плащането в ecoints или автоматично ще бъде прекратено след 1 час.': 'Може да откажеш плащането в ecoints или автоматично ще бъде прекратено след 1 час.',
  'Отказа плащане с ecoints': 'Отказа плащане с ecoints',
  'Плащането с ecoints не е валидно': 'Плащането с ecoints не е валидно',
  'Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш с ecoints с известие или QR код?': 'Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш с ecoints с известие или QR код?',
  'Твоят избор за бъдещи плащания е по банков път. Желаеш ли да го промениш и плащаш с ecoints с известие или QR код?': 'Твоят избор за бъдещи плащания е по банков път. Желаеш ли да го промениш и плащаш с ecoints с известие или QR код?',
  'Избери ecoints за бъдещи плащания с известие или QR код.': 'Избери ecoints за бъдещи плащания с известие или QR код.',
  'Успешно плати с ecoints': 'Успешно плати с ecoints',
  'Няма дължими суми по пратка.': 'Няма дължими суми по пратка.',
  'Документ за плащане': 'Документ за плащане',
  'Общa дължима сума:': 'Общa дължима сума:',
  'при плащане': 'при плащане',
  'с ecoints': 'с ecoints',
  'по банков път': 'по банков път',
  'Български': 'Български',
  'Английски': 'Английски',
  'Език': 'Език',
  'Успешно заяви плащане с ecoints': 'Успешно заяви плащане с ecoints',
  'Клиентски номер': 'Клиентски номер',
  'Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш с клиентски номер?': 'Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш с клиентски номер?',
  'С клиентски номер': 'С клиентски номер',
  'Успешно заяви плащане с клиентски номер': 'Успешно заяви плащане с клиентски номер',
  'Запази шаблон': 'Запази шаблон',
}

const bgLang = (key: string) => bg[key]

export default bgLang