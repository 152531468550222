import { OverflowMenu, styled } from "econt-design-system"
import { useState } from "react"
import { useT } from "../translations/useTranslations"

const LanguageBar = (props: any) => {
  const [openLanguages, setOpenLanguages] = useState<boolean>(false)
  const t = useT()

  return (
    <OverflowMenu
      options={props.options}
      open={openLanguages}
      setOpen={setOpenLanguages}
      placement='start'
    >
      <Link $open={openLanguages} onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setOpenLanguages(!openLanguages)
      }}
      >
        {t('Език')}
      </Link>
    </OverflowMenu>
  )
}

const Link = styled.div<{$open: boolean}>`
  font-size: 14px;
  letter-spacing: 0.28px;
  line-height: 19px;
  color: ${({theme}) => theme.colors.grey[30]};
  cursor: pointer;
  padding: 7px 0;

  &:hover {
    color: ${({theme}) => theme.colors.white};
  }

  ${({ $open, theme }) => $open ? `
    color: ${theme.colors.white};
  ` : ``}
`

export default LanguageBar