import { Document, LoadingAnimation, ProcessOutcomeMessage, SummaryDocument, TertiaryButton, styled } from "econt-design-system"
import PaymentMethods from "../components/PaymentMethods"
import EcointsModal from "../components/EcointsModal"
import { usePayment } from "../context/PaymentContext"
import { Fragment } from "react"
import { BottomWrapper } from "./BankSuccessfulPayment"
import { useT } from "../translations/useTranslations"
import PackageLink from "../components/PackageLink"
import { MOBILE_APP_ECONT } from "../utils/constants"
import { useSearchParams } from "react-router-dom"

const HowToPay = () => {
  const { loadings, ecoints, isLoading, data, callbackUrl, payer, totals } = usePayment()
  const t = useT()
  const [searchParams] = useSearchParams();
  const appSourceParam = searchParams.get('AppSource')

  const allLoadingArePaid = !loadings?.find(loading => loading.is_paid === 0)

  if ((loadings || []).length > 0 && allLoadingArePaid) {
    return (
      <SmallContainer>
        <ProcessOutcomeMessage
          headline={t("Няма дължими суми по пратка.")}
          type="default"
          icon="shipment_document_big"
        >
          {loadings && payer === 'sender' ? <PackageLink loadings={loadings} /> : null}
        </ProcessOutcomeMessage>
        <BottomWrapper>
          <div>
            <TertiaryButton icon="arrow_back" type="neutral" text={t("Към начало")} onClick={() => {
              if (appSourceParam?.toLowerCase?.() === MOBILE_APP_ECONT) {
                window.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    type: 'backToHome',
                  }),
                )
              } else {
                window.location.href = callbackUrl
              }
            }} />
          </div>
        </BottomWrapper>
      </SmallContainer>
    )
  }


  return (
    <Container $ecointsModal={ecoints === false}>
      <BigWrapper>
        {!isLoading ? (  
          <PaymentMethods />
        ): (
          <LoadingAnimation size="large" />
        )}
      </BigWrapper>
      <SmallWrapper $isLoading={isLoading}>
        {!isLoading ? (
          <SummaryDocument
            fullWidth
            headline={t("Документ за плащане")}
            logo={true}
            footerLabel={t("Общa дължима сума:")}
            footerValue={data.paymentMethod === 'cash' || !data.paymentMethod ? `
              ${Number.parseFloat(`${totals?.total_sum || '0'}`).toFixed(2)} ${totals?.total_sum_currency || ''}
            ` : (totals?.total_sum_bank ? (`${Number.parseFloat(`${totals?.total_sum_bank || '0'}`).toFixed(2)} ${totals?.total_sum_bank_currency || ''}`) : `${Number.parseFloat(`${totals?.total_sum || '0'}`).toFixed(2)} ${totals?.total_sum_currency || ''}`)}
            noFooterPadding
          >
            {loadings?.map((loading: any) => {
              // такса наложен платеж
              const npBankService = loading.services?.find((s: any) => s.is_bank === 1)
              const npCashService = loading.services?.find((s: any) => s.is_bank === 0)

              const discount = Number(npCashService?.total_sum || '0') - Number(npBankService?.total_sum || '0')
              
              if (loading.is_paid === 1) {
                return <Fragment key={loading.num}></Fragment>
              }

              return (
                <Document key={loading.num} headline={`${t('Пратка')} № ${loading.num || ''}`} isEditable={false}>
                  {loading.services?.map((service: any, index: number) => {
                    if ((data.paymentMethod === 'cash' || !data.paymentMethod) && service.is_bank === 1) {
                      return (
                        <Fragment key={index} />
                      )
                    }
                    if ((data.paymentMethod !== 'cash' && data.paymentMethod) && service.is_bank === 0) {
                      return (
                        <Fragment key={index} />
                      )
                    }
                    const hasDiscount = payer === 'sender' ? false : (data.paymentMethod !== 'cash' && data.paymentMethod) && service.is_bank === 1 && discount > 0
                    return (
                      <Fragment key={index}>
                        <InnerWrapper>
                          <Text>{service.description}</Text>
                          <Amount $hasDiscount={!!hasDiscount}>{Number.parseFloat(`${(service.is_bank === 0 || service.is_bank === 1) ? npCashService.total_sum : service.total_sum}`).toFixed(2)} {service.currency}.</Amount>
                        </InnerWrapper>
                        {hasDiscount ? (
                          <InnerWrapper>
                            <Text $discount>-{Number.parseFloat(`${discount}`).toFixed(2)} {service.currency}. {t('при плащане')} {data.paymentMethod === 'ecoints' ? t('с ecoints') : t('по банков път')}</Text>
                            <Amount>{Number.parseFloat(`${service.total_sum}`).toFixed(2)} {service.currency}.</Amount>
                          </InnerWrapper>
                        ): null}
                      </Fragment>
                    )
                  })}
                </Document>
              )
            })}
          </SummaryDocument>
        ) : (
          <LoadingWrapper>
            <LoadingAnimation size="large" />
          </LoadingWrapper>
        )}
      </SmallWrapper>
      <EcointsModal ecoints={ecoints} />
    </Container>
  )
}

const Container = styled.div<{$ecointsModal?: boolean}>`
  display: flex;
  flex-direction: row;
  max-width: 921px;
  margin: 64px auto;
  gap: 47px;

  ${({$ecointsModal}) => $ecointsModal ? `
    margin: 64px auto 164px;
  `: ``}

  @media screen and (max-width: 968px) {
    flex-direction: column;
    max-width: 460px;
    margin: 24px auto;
    gap: 16px;
    padding: 0 16px;

    ${({$ecointsModal}) => $ecointsModal ? `
      margin: 64px auto;
    `: ``}
  }

`


const SmallContainer = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 87px auto 24px;

  & > div {
    width: 100%;
  }

  @media screen and (max-width: 968px) {
    margin: 48px auto 24px;
    padding: 0 16px;
  }
`

const SmallWrapper = styled.div<{$isLoading?: boolean}>`
  width: 100%;
  max-width: 376px;
  border-radius: ${({theme}) => theme.borderRadius.m};

  ${({$isLoading, theme}) => $isLoading ? `
    background: ${theme.colors.white};
  `: ``}

  @media screen and (max-width: 968px) {
    max-width: 100%;
    order: 1;
    display: flex;
  }
`

const BigWrapper = styled.div`
  width: 100%;
  max-width: 498px;
  padding: 32px;
  background: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius.m};
  
  @media screen and (max-width: 968px) {
    max-width: 100%;
    order: 2;
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 735px) {
    padding: 16px;
  }
`

export const PackageMessage = styled.div`
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.4px;
  margin-top: 8px;

  & svg {
    width: 15px;
    height: 15px;
  }

  @media screen and (max-width: 735px) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.32px;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

const Text = styled.div<{$discount?: boolean}>`
  color: ${({theme}) => theme.colors.grey[60]};
  font-size: 14px;

  ${({$discount, theme}) => $discount ? `
    color: ${theme.colors.green[30]};
  `: ``}
`

const Amount = styled.div<{$hasDiscount?: boolean}>`
  color: ${({theme}) => theme.colors.grey[40]};
  font-size: 14px;
  flex-shrink: 0;

  ${({$hasDiscount}) => $hasDiscount ? `
    text-decoration: line-through;
  `: ``}
`

const LoadingWrapper = styled.div`
  padding: 32px;
  margin: 0 auto;
`

export default HowToPay