import { Button, FileUploader, InlineMessage, Modal, SecondaryButton, TextInput, Textarea, ToastNotification, styled } from "econt-design-system"
import { FileAttachmentProps } from "econt-design-system/build/components/FileAttachment/FileAttachment"
import { useState } from "react"
import client from "../utils/client"
import { usePayment } from "../context/PaymentContext"
import { useT } from "../translations/useTranslations"
import { useLanguage } from "../context/LanguageContext"

interface SignalModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  phoneNumber: string
}

const SignalModal = ({ open, setOpen, phoneNumber }: SignalModalProps) => {
  const { packageNumbers } = usePayment()
  const [reason, setReason] = useState<string>('')
  const [uploaderError, setUploaderError] = useState<string>('')
  const [files, setFiles] = useState<FileAttachmentProps[]>([])
  const [info, setInfo] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const t = useT()
  const { lang } = useLanguage()

  const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  })

  const disableBtn = !(reason?.length >= 5 && info?.length >= 10)

  const handleConfirm = async () => {
    if (disableBtn) {
      return
    }
    setIsLoading(true)
    const base64Files = []

    for (let i = 0; i < files?.length; i++) {
      base64Files.push(await toBase64(files[i].file))
    }

    const result = await client.post('/reportBug', {
      title: reason,
      description: info,
      client_phone: phoneNumber,
      loading_nums: packageNumbers,
      system: window.navigator.userAgent,
      images: base64Files,
      lang
    })

    setIsLoading(false)
    if (result?.data?.result) {
      setOpen(false)
      setOpenToast(true)
    } else {
      setError(true)
    }
  }

  const deleteFile = (id?: string | number) => {

    setFiles((prevFiles) => {
      return prevFiles.filter((_, i) => i !== id)
    })
  }


  return (
    <>
      <Modal open={open} setOpen={setOpen} closeButton={false} Header={<Title>{t('Сигнал')}</Title>} Footer={
        <Footer>
          <div>
            <FooterWrapper>
              <SecondaryButton text={t("Откажи")} size="large" onClick={() => {
                setOpen(false)
              }} />
              <Button text={t("Изпрати сигнала")} size="large" loading={isLoading} disabled={disableBtn} onClick={handleConfirm} />
            </FooterWrapper>
            <MobileFooterWrapper>
              <SecondaryButton text={t("Откажи")} size="medium" fullWidth onClick={() => {
                setOpen(false)
              }} />
              <Button text={t("Изпрати")} size="medium" loading={isLoading} fullWidth disabled={disableBtn} onClick={handleConfirm} />
            </MobileFooterWrapper>
          </div>
          {error ? (
            <div>
              <InlineMessage type="error" text={t("Възникна грешка, моля опитайте отново")} />
            </div>
          ) : null}
        </Footer>
      }>
        <Wrapper>
          <TextInput placeholder={t("Причина за сигнала")} value={reason} onChange={e => {
            if (error) {
              setError(false)
            }
            setReason(e.target.value)
          }} fullWidth assistiveText={t("Минимум 5 символа")} />
          <Textarea placeholder={t("Моля, сподели ни повече информация.")} value={info} onChange={e => {
            if (error) {
              setError(false)
            }
            setInfo(e.target.value)
          }} assistiveText={t("Минимум 10 символа")} />
          <FileUploader
            files={files}
            size="small"
            accept={{
              'image/*': ['.jpeg', '.png'],
              'video/*': ['.mp4', '.avi', '.mkv', '.mov']
            }}
            maxFiles={5}
            error={uploaderError}
            onError={() => {
              setUploaderError(t('Може да качите до 5 снимки/видеа.'))
            }}
            onDrop={(newFiles) => {
              if (error) {
                setError(false)
              }
              if (uploaderError) {
                setUploaderError('')
              }
              if (files.length >= 5) {
                setUploaderError(t('Може да качите до 5 снимки/видеа.'))
                return
              }
              const imageFiles = newFiles.filter(f => {
                return f.type.includes('image') || f.type.includes('video')
              })

              if (files.length + imageFiles.length > 5) {
                setUploaderError(t('Може да качите до 5 снимки/видеа.'))
                return
              }

              const droppedfiles = imageFiles.map(f => {
                return {
                  file: f,
                  isUploading: false,
                  thumbnailURL: URL.createObjectURL(f),
                  deleteLabel: t('Изтрий'),
                  onDelete: deleteFile
                }
              })

              setFiles([...droppedfiles, ...files])
            }}
            label={t("Добави снимка")}
            headline={t("Прикачи файл")}
            icon="plus"
            assistiveText={t("До 5 снимки/видеа")}
          />
        </Wrapper>
      </Modal>
      <ToastNotification text={t("Успешно изпрати сигнал")} type="success" trigger={openToast} setTrigger={setOpenToast} />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: ${({theme}) => theme.colors.grey[70]};
  margin: 0 0 32px;

  @media screen and (max-width: 735px) {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0;
  }
`

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;

  @media screen and (max-width: 735px) {
    display: none;
  }
`

const MobileFooterWrapper = styled.div`
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 26px;

  @media screen and (max-width: 735px) {
    display: flex;
    justify-content: space-evenly;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export default SignalModal