import { Navbar } from 'econt-design-system';
import React, { useState } from 'react';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import HowToPay from './pages/HowToPay';
import EcointsPaymentRequest from './pages/EcointsPaymentRequest';
import BankSuccessfulPayment from './pages/BankSuccessfulPayment';
import EcointsSuccessfulPayment from './pages/EcointsSuccessfulPayment';
import CashSuccessfulPayment from './pages/CashSuccessfulPayment';
import PaymentContextProvider from './context/PaymentContext';
import LanguageBar from './components/LanguageBar';
import useTranslations from './translations/useTranslations';
import LanguageContextProvider from './context/LanguageContext';
import CreditSuccessfulPayment from './pages/CreditSuccessfulPayment';

const PageWrapper = () => {
  const location = useLocation();
  const isEn = location.pathname?.includes('/en')
  const t = useTranslations(isEn ? 'en' : 'bg')
  const navigate = useNavigate();

  const options = [
    {
      name: t('Български'),
      icon: !isEn ? 'check' : '',
      rightPosition: true,
      onClick: () => navigate(`${location.pathname.replace('/en', '')}${location.search}`)
    },
    {
      name: t('Английски'),
      onClick: () => {
        if (isEn) {
          return
        }
        if (location.pathname?.includes('/bg')) {
          navigate(`${location.pathname.replace('/bg', '/en')}${location.search}`)
          return
        }
        navigate(`/en${location.pathname}${location.search}`)
      },
      icon: isEn ? 'check' : '',
      rightPosition: true,
    },
  ]
  return (
    <LanguageContextProvider>
      <PaymentContextProvider>
        <Navbar
          logo='/images/econt_logo.svg'
          navLinks={[]}
          label={t('Плати онлайн')}
          sticky
          languages={<LanguageBar options={options} />}
        />
        <Outlet />
      </PaymentContextProvider>
    </LanguageContextProvider>
  )
}

function App() {
  

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageWrapper />}>
          <Route
            path=""
            element={<HowToPay />}
          />
          <Route path="ecoints-payment-request" element={<EcointsPaymentRequest />} />
          <Route path="ecoints-successful-payment" element={<EcointsSuccessfulPayment />} />
          <Route path="bank-successful-payment" element={<BankSuccessfulPayment />} />
          <Route path="cash-successful-payment" element={<CashSuccessfulPayment />} />
          <Route path="credit-successful-payment" element={<CreditSuccessfulPayment />} />
        </Route>
        <Route path="/bg" element={<PageWrapper />}>
          <Route
            path=""
            element={<HowToPay />}
          />
          <Route path="ecoints-payment-request" element={<EcointsPaymentRequest />} />
          <Route path="ecoints-successful-payment" element={<EcointsSuccessfulPayment />} />
          <Route path="bank-successful-payment" element={<BankSuccessfulPayment />} />
          <Route path="cash-successful-payment" element={<CashSuccessfulPayment />} />
          <Route path="credit-successful-payment" element={<CreditSuccessfulPayment />} />
        </Route>
        <Route path="/en" element={<PageWrapper />}>
          <Route
            path=""
            element={<HowToPay />}
          />
          <Route path="ecoints-payment-request" element={<EcointsPaymentRequest />} />
          <Route path="ecoints-successful-payment" element={<EcointsSuccessfulPayment />} />
          <Route path="bank-successful-payment" element={<BankSuccessfulPayment />} />
          <Route path="cash-successful-payment" element={<CashSuccessfulPayment />} />
          <Route path="credit-successful-payment" element={<CreditSuccessfulPayment />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
