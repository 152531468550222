const getInstructionLink = (loading: any) => {
  if (loading?.instruction_link) {
    if (loading?.instruction_link?.includes('http://') || loading?.instruction_link?.includes('https://')) {
      return loading.instruction_link
    } else {
      return `https://${loading.instruction_link}`
    }
  }

  return null
}

export default getInstructionLink