import { Button, Modal, styled } from "econt-design-system"
import { useEffect, useState } from "react"
import { getMobileOS } from "./EcointsModal"
import { useT } from "../translations/useTranslations"


const ConfirmNoEcointsModal = ({ open, setOpen }: { open: boolean, setOpen: (o: boolean) => void }) => {
  const [os, setOs] = useState<string>('')
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const t = useT()

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 968px)')

    if (mediaQuery.matches) {
      setIsMobile(true)
    }
    setOs(getMobileOS())
  }, [])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      closeButton={false}
    >
      <Title>{t('Моля, използвай телефонния номер, с който си се регистрирал, или изтегли ecoints.')}</Title>
      <Description>{t('Липсва профил с избрания от теб номер.')}</Description>
      <DownloadContainer>
        <ScanWrapper>
          {
            isMobile ? (
              <>
                <Anchor href="https://apps.apple.com/bg/app/ecoints-wallet-by-econt/id1599108381" $hide={!(os === 'iOS' || os === 'Macintosh')} rel="noopener noreferrer" target="_blank">
                  <img src="/images/app-store.svg" alt="apple store" />
                </Anchor>
                <Anchor href="https://play.google.com/store/apps/details?id=com.econt.mwallet.prod" $hide={!(os === 'Android' || os === 'Windows')} rel="noopener noreferrer" target="_blank">
                  <img src="/images/google-play.svg" alt="google play" />
                </Anchor>
                <Anchor href="https://appgallery.huawei.com/app/C106858735" $hide={os !== 'huawei'} rel="noopener noreferrer" target="_blank">
                  <img src="/images/huawei.svg" alt="huawei store" />
                </Anchor>
              </>
            ) : (
              <>
                <img src="/images/qr-2.png" alt="qr" width="60" height="60" />
                <ScanMsg>{t('Сканирай и изтегли')}</ScanMsg> 
              </>
            )
          }
        </ScanWrapper>
        <Button text={t("Затвори")} size="large" onClick={() => setOpen(false)} />
      </DownloadContainer>
    </Modal>
  )
}

const Title = styled.h4`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  margin-top: 0;
  color: ${({theme}) => theme.colors.grey[70]};
  
  @media screen and (max-width: 968px) {
    font-size: 20px;
    line-height: 26px;
  }
`

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 32px;

  @media screen and (max-width: 968px) {
    align-items: center;
  }
`

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  color: ${({theme}) => theme.colors.grey[60]};
`

const ScanMsg = styled.div`
  width: 60px;
  font-size: 12px;
  line-height: 15px;
  color: ${({theme}) => theme.colors.grey[50]};
`

const ScanWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`

const Anchor = styled.a`
  display: flex;

  ${({ $hide }: {$hide?: boolean}) => $hide ? `
    display: none;
  `: ``}
`

export default ConfirmNoEcointsModal