import { Modal, StickyBanner, TextLink, styled } from "econt-design-system"
import { useEffect, useRef, useState } from "react"
import { useT } from "../translations/useTranslations"

export const getMobileOS = () => {
  const ua = navigator.userAgent

  if (/android/i.test(ua)) {
    return "Android"
  }
  else if (/Macintosh/.test(ua)) {
    return 'Macintosh'
  }
  else if (/Windows/.test(ua)) {
    return 'Windows'
  }
  else if (/iPad|iPhone|iPod/.test(ua)) {
    return "iOS"
  }
  else if (/huawei/.test(ua)) {
    return "huawei"
  }
  return "Other"
}

const EcointsModal = ({ ecoints }: { ecoints?: boolean | null }) => {
  const [os, setOs] = useState<string>('')
  const [openEcointsModal, setOpenEcointsModal] = useState<boolean>(false)
  const [openAnimation, setOpenAnimation] = useState<boolean>(openEcointsModal)
  const isFirstLoading = useRef(true)
  const t = useT()

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 968px)')

    const timeout = setTimeout(() => {
      if (!mediaQuery.matches && ecoints === false && isFirstLoading.current) {
        setOpenEcointsModal(true)
        setOpenAnimation(true)
        isFirstLoading.current = false
      }
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [ecoints])

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 968px)')

    if (mediaQuery.matches) {
      setOs(getMobileOS())
    }
  }, [os])

  if (ecoints === null || ecoints === undefined || ecoints === true) {
    return null
  }

  return (
    <>
      <StickyWrapper>
        <StickyBanner top={65}>
          <StickyContainer>
            <StickyHeadline>{t('Дигитален портфейл ecoints')}</StickyHeadline>
            <ButtonsWrapper>
              <Anchor href="https://apps.apple.com/bg/app/ecoints-wallet-by-econt/id1599108381" $hide={!(os === 'iOS' || os === 'Macintosh')} rel="noopener noreferrer" target="_blank">
                <img src="/images/app-store.svg" alt="apple store" />
              </Anchor>
              <Anchor href="https://play.google.com/store/apps/details?id=com.econt.mwallet.prod" $hide={!(os === 'Android' || os === 'Windows')} rel="noopener noreferrer" target="_blank">
                <img src="/images/google-play.svg" alt="google play" />
              </Anchor>
              <Anchor href="https://appgallery.huawei.com/app/C106858735" $hide={os !== 'huawei'} rel="noopener noreferrer" target="_blank">
                <img src="/images/huawei.svg" alt="huawei store" />
              </Anchor>
            </ButtonsWrapper>
          </StickyContainer>
        </StickyBanner>
      </StickyWrapper>
      <MessageWrapper $open={openAnimation} onClick={() => {
        setOpenEcointsModal(true)
        setOpenAnimation(true)
      }}>
        <MessageInfo>
          <Wallet>{t('Дигиталният портфейл')}</Wallet>
          <img src="/images/ecoints.svg" alt="ecoints logo" width={110} height={34} />
        </MessageInfo>
        <img src="/images/qr-2.png" alt="qr" width="60" height="60" />
      </MessageWrapper>
      <Modal
        open={openEcointsModal}
        setOpen={setOpenEcointsModal}
        onClose={() => {
          setOpenAnimation(false)
        }}
        Header={
          <img src="/images/ecoints.svg" alt="ecoints logo" width={143} height={44} />
        }
      >
        <Title>{t('Ползи за теб с дигиталния портфейл на Еконт:')}</Title>
        <UL>
          <Li>{t('плащаш лесно куриерските си услуги, където и да си')}</Li>
          <Li>{t('плащаш с един клик пратките си на място')}</Li>
          <Li>{t('пращаш и получаваш пари от приятел за секунди.')}</Li>
        </UL>
        <LearnMore>{t('Научи повече на')} <TextLink href="https://www.ecoints.com/" text="ecoints.com" external /></LearnMore>

        <DownloadContainer>
          <Stores>
            <Anchor href="https://apps.apple.com/bg/app/ecoints-wallet-by-econt/id1599108381" rel="noopener noreferrer" target="_blank">
              <img src="/images/app-store.svg" alt="apple store" />
            </Anchor>
            <Anchor href="https://play.google.com/store/apps/details?id=com.econt.mwallet.prod" rel="noopener noreferrer" target="_blank">
              <img src="/images/google-play.svg" alt="google play" />
            </Anchor>
            <Anchor href="https://appgallery.huawei.com/app/C106858735" rel="noopener noreferrer" target="_blank">
              <img src="/images/huawei.svg" alt="huawei store" />
            </Anchor>
          </Stores>
          <ScanWrapper>
            <ScanMsg>{t('Сканирай и изтегли')}</ScanMsg> 
            <img src="/images/qr-2.png" alt="qr" width="60" height="60" />
          </ScanWrapper>
        </DownloadContainer>
      </Modal>
    </>
  )
}

const MessageWrapper = styled.div<{$open?: boolean}>`
  box-shadow: 4px 7px 25px #00000017;
  background: ${({theme}) => theme.colors.white};
  width: 350px;
  border-radius: 10px;
  position: fixed;
  bottom: 50px;
  right: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding: 20px;
  cursor: pointer;
  transition: all .4s;
  opacity: 1;

  ${({$open}) => $open ? `
    bottom: calc(50% - 100px);
    right: calc(50% - 350px);
    width: 700px;
    height: 200px;
    opacity: 0;
  `: ``}

  @media screen and (max-width: 968px) {
    display: none;
  }
`

const StickyContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const StickyHeadline = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  font-family: ${({theme}) => theme.fonts.primary.bold};
  letter-spacing: 0.28px;
  color: ${({theme}) => theme.colors.grey[70]};
`

const StickyWrapper = styled.div`
  display: none;
  
  @media screen and (max-width: 968px) {
    display: block;
  }
`

const MessageInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
`

const Title = styled.h4`
  font-size: 24px;
  line-height: 32px;
  margin-top: 24px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.grey[70]};
`

const Li = styled.li`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  color: ${({theme}) => theme.colors.grey[70]};
`

const UL = styled.ul`
  margin: 0;
`

const LearnMore = styled.p`
  color: ${({theme}) => theme.colors.grey[70]};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.32px;
  margin: 12px 0 36px;
`

const Wallet = styled.div`
  color: ${({theme}) => theme.colors.grey[70]};
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.32px;
`

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Stores = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const ScanMsg = styled.div`
  width: 60px;
  font-size: 12px;
  line-height: 15px;
  color: ${({theme}) => theme.colors.grey[50]};
`

const ScanWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`

const Anchor = styled.a`
  display: flex;

  ${({ $hide }: {$hide?: boolean}) => $hide ? `
    display: none;
  `: ``}
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-left: 12px;
  align-items: center;
`

export default EcointsModal