import { Button, Icon, ProcessOutcomeMessage, TertiaryButton, TextLink, ToggleGroup, styled } from "econt-design-system"
import { useCallback, useEffect, useState } from "react";
import { usePayment } from "../context/PaymentContext";
import client from "../utils/client";
import getInstructionLink from "../utils/getInstructionLink";
import { useT } from "../translations/useTranslations";
import PackageLink from "../components/PackageLink";
import { useLanguage } from "../context/LanguageContext";
import { MOBILE_APP_ECONT } from "../utils/constants";
import { useSearchParams } from "react-router-dom";
import { SaveTemplateWrapper } from "./CashSuccessfulPayment";

const CreditSuccessfulPayment = () => {
  const { loadings, callbackUrl, defaultMethod, payer } = usePayment()
  const [saveCredit, setSaveCredit] = useState<boolean>(false)
  const currentLoading = loadings?.[0]
  const defMethod = defaultMethod?.[currentLoading?.client_id]?.[0]?.payment_method
  const t = useT()
  const { lang } = useLanguage()
  const [searchParams] = useSearchParams()
  const appSourceParam = searchParams.get('AppSource')
  const showTemplateParam = searchParams.get('show_template')
  const courierRequestUrl = searchParams.get('courier_request_url')

  useEffect(() => {
    if (defMethod === 'credit') {
      setSaveCredit(true)
    }
  }, [defMethod])

  const getHeadline = useCallback(() => {
    if (defMethod === 'ecoints') {
      return t("Твоят избор за бъдещи плащания е ecoints. Желаеш ли да го промениш и плащаш с клиентски номер?")
    }
    if (defMethod === 'borica') {
      return t("Твоят избор за бъдещи плащания е по банков път. Желаеш ли да го промениш и плащаш с клиентски номер?")
    }
    if (defMethod === 'cash') {
      return t("Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш с клиентски номер?")
    }
    return t('Избери с клиентски номер за бъдещи плащания.')
  }, [defMethod, t])

  const hasPackageFromAddress = loadings && loadings?.length > 0 ? loadings?.find(loading => {
    return loading.sender_delivery === 'door'
  }) : false

  return (
    <Container>
      <ProcessOutcomeMessage headline={`${t("Успешно заяви плащане с клиентски номер")}`} text={<Description>{t('Ще получиш електронен касов бон на имейла си.')}</Description>}>
        <>
          {loadings && payer === 'sender' ? <PackageLink loadings={loadings} /> : null}
          {
            currentLoading?.instruction_link && appSourceParam?.toLowerCase?.() !== MOBILE_APP_ECONT ? (
              <OtherMessage>
                {t('Промени всяко условие на доставката с')} <TextLink text={t("услугата Разпореждане")} href={getInstructionLink(currentLoading)} />
              </OtherMessage>
            ) : null
          }
          {currentLoading?.client_id && defMethod !== 'credit' ? (
            <ToggleGroupWrapper>
              <ToggleGroup
                name="save-credit"
                headline={getHeadline()}
                imageComponent={(
                  <BankLabelWrapper>
                    <StyledIcon name="person_legal_enitity" />
                    <Label>{t('С клиентски номер')}</Label>
                  </BankLabelWrapper>
                )}
                // assistiveText={<AssistiveText>Можеш да промениш избора си по всяко време от <TextLink text="профила си в Еконт." href="https://ee.econt.com/" onClick={(e) => {
                //   e.stopPropagation()
                // }} /></AssistiveText>}
                checked={saveCredit}
                onClick={async () => {
                  const result = await client.post('/setClientDesiredPaymentMethod', {
                    id_client: currentLoading.client_id,
                    payment_method: saveCredit ? 'none' : 'credit',
                    payment_side: payer,
                    lang
                  })

                  if (result?.data?.result) {
                    window.dataLayer.push({
                      'event' : 'DefaultPaymentMethod',
                      'UX_Action' : 'successful',
                      'payment_method' : saveCredit ? 'None' : 'Credit'
                    });
                    setSaveCredit(!saveCredit)
                  } else {
                    window.dataLayer.push({
                      'event' : 'DefaultPaymentMethod',
                      'UX_Action' : 'failed',
                      'payment_method' : 'Credit'
                    });
                  }
                }}
              />
            </ToggleGroupWrapper>
          ): null}
          {
            showTemplateParam ? (
              <SaveTemplateWrapper>
                <TertiaryButton text={t("Запази шаблон")} type="neutral" icon="save" iconPosition="right" onClick={() => {
                  window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                      type: 'saveTemplate',
                    }),
                  )
                }} />
              </SaveTemplateWrapper>
            ) : null
          }
          {hasPackageFromAddress ? (
            <Button text={t("Заяви куриер")} size="large" onClick={() => {
              if (appSourceParam?.toLowerCase?.() === MOBILE_APP_ECONT) {
                window.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    type: 'requestCourier',
                  }),
                )
              } else {
                window.location.href = courierRequestUrl || 'https://www.econt.com/services/order-courier'
              }
            }} />
          ) : null}
        </>
      </ProcessOutcomeMessage>

      <BottomWrapper>
        <div>
          <TertiaryButton icon="arrow_back" type="neutral" text={t("Към начало")} onClick={() => {
            if (appSourceParam?.toLowerCase?.() === MOBILE_APP_ECONT) {
              window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  type: 'backToHome',
                }),
              )
            } else {
              window.location.href = callbackUrl
            }
          }} />
        </div>
      </BottomWrapper>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 87px auto 24px;

  & > div {
    width: 100%;
  }

  @media screen and (max-width: 968px) {
    margin: 48px auto 24px;
    padding: 0 16px;
  }
`

const ToggleGroupWrapper = styled.div`
  text-align: left;
  margin-bottom: 32px;
  margin-top: 32px;
  width: 100%;

  & > div {
    width: 100%;
  }
`

const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  text-align: center;
`

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  color: ${({theme}) => theme.colors.grey[60]};

  @media screen and (max-width: 735px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
`

const OtherMessage = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  color: ${({theme}) => theme.colors.grey[60]};

  @media screen and (max-width: 735px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
`

const BankLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

const StyledIcon = styled(Icon)`
  fill: ${({theme}) => theme.colors.grey[70]};
`

const Label = styled.span`
  font-size: 18px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.grey[70]};

  @media screen and (max-width: 735px) {
    font-size: 15px;
  }
`

const AssistiveText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #747373;
`

export default CreditSuccessfulPayment