const en: { [key: string]: string } = {
  'Плати онлайн': 'Pay online',
  'Моля, използвай телефонния номер, с който си се регистрирал, или изтегли ecoints.': 'Please use the phone number with which you have registered, or download ecoints.',
  'Липсва профил с избрания от теб номер.': 'There is no account with the number selected by you.',
  'Сканирай и изтегли': 'Scan and download',
  'Затвори': 'Close',
  'Дигитален портфейл ecoints': 'Digital wallet ecoints',
  'Дигиталният портфейл': 'Digital wallet',
  'Ползи за теб с дигиталния портфейл на Еконт:': 'Benefits for you from Econt’s digital wallet:',
  'плащаш лесно куриерските си услуги, където и да си': 'you can easily pay your courier services wherever you are',
  'плащаш с един клик пратките си на място': 'you can pay with one click your shipments on location',
  'пращаш и получаваш пари от приятел за секунди.': 'you can send and receive money from a friend in seconds.',
  'Научи повече на': 'Learn more at',
  'Изникна проблем, моля опитайте пак': 'A problem occurred, please try again.',
  'Избери как ще платиш': 'Choose how you will pay',
  'Предпочитано': 'Preferred',
  'Телефонен номер за потвърждение на плащане': 'Phone number for payment confirmation',
  'Ще платя на място чрез QR код': 'I will pay on location via QR code',
  'Банкова карта': 'Bank card',
  'Въведи имейл, на който да получиш своя касов бон': 'Enter an email address on which to receive your receipt',
  'Въведи имена на картодържателя по банкова карта на латиница': 'Enter the cardholder\'s name in Latin script on the bank card',
  'Въведеното име е непълно.': 'The entered name is incomplete',
  'Въведи две имена на картодържателя по банкова карта на латиница': 'Enter two names of the cardholder in Latin script on the bank card',
  'Въведените символите трябва да са само на латиница [a-Z]': 'The entered characters must be in Latin script only',
  'В брой': 'In cash',
  'Назад': 'Back',
  'Потвърди': 'Confirm',
  'Сигнал': 'Alert',
  'Откажи': 'Cancel',
  'Изпрати сигнала': 'Send an alert',
  'Изпрати': 'Send',
  'Възникна грешка, моля опитайте отново': 'An error occurred, please try again.',
  'Причина за сигнала': 'Reason for the alert',
  'Минимум 5 символа': 'Minimum 5 characters',
  'Моля, сподели ни повече информация.': 'Please share more information with us.',
  'Минимум 10 символа': 'Minimum 10 characters',
  'Може да качите до 5 снимки/видеа.': 'You can upload up to 5 photos/videos.',
  'Изтрий': 'Delete',
  'Добави снимка': 'Add a photo',
  'Прикачи файл': 'Attach a file',
  'До 5 снимки/видеа': 'Up to 5 photos/videos',
  'Успешно изпрати сигнал': 'You successfully sent an alert.',
  'Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш по банков път?': 'Your choice for future payments is in cash. Do you wish to change it and to pay by bank transfer?',
  'Твоят избор за бъдещи плащания е ecoints. Желаеш ли да го промениш и плащаш по банков път?': 'Your choice for future payments is ecoints. Do you wish to change it and to pay by bank transfer?',
  'Избери по банков път за бъдещи плащания.': 'Choose by bank transfer for future payments.',
  'Плащането е неуспешно': 'The payment was unsuccessful',
  'Избери друг начин на плащане': 'Choose another payment method',
  'Успешно плати с банкова карта': 'You paid successfully with a bank card',
  'Ще получиш електронен касов бон на имейла си.': 'You will receive an electronic receipt on your email when you pay.',
  'Номер на пратка:': 'Shipment number:',
  'Пратка': 'Shipment',
  'Промени всяко условие на доставката с': 'Change every term of the delivery with',
  'услугата Разпореждане': 'the Change Delivery service',
  'Заяви куриер': 'Order a courier',
  'Към начало': 'To home',
  'Твоят избор за бъдещи плащания е ecoints. Желаеш ли да го промениш и плащаш в брой?': 'Your choice for future payments is ecoints. Do you wish to change it and to pay in cash?',
  'Твоят избор за бъдещи плащания е по банков път. Желаеш ли да го промениш и плащаш в брой?': 'Your choice for future payments is by bank transfer. Do you wish to change it and to pay in cash?',
  'Избери в брой за бъдещи плащания.': 'Choose in cash for future payments.',
  'Успешно заяви плащане в брой': 'You successfully ordered a payment in cash.',
  'Ще получиш електронен касов бон на имейла си, когато платиш.': 'You will receive an electronic receipt on your email when you pay.',
  'В брой при изпращане': 'In cash when sending',
  'Изпратена е нотификация': 'A notification was sent.',
  'Потвърди трансакцията в приложение ecoints.': 'Confirm the transaction in the ecoints app.',
  'Успешно заяви плащане в ecoints': 'You successfully ordered a payment in ecoints',
  'Нужно е само да го потвърдиш.': 'You just need to confirm it.',
  'Не получаваш известие?': 'You are not receiving a notification?',
  'Липсва известие на екрана ти?': 'There is no notification on your screen?',
  'Можеш да подадеш сигнал за нередност.': 'You can submit an alert for a problem.',
  'Изпрати известието отново': 'Send a notification again',
  'Опитай отново': 'Try again',
  'Ще платя по друг начин': 'I will pay using another method',
  'Изпрати сигнал': 'Send an alert',
  'Може да откажеш плащането в ecoints или автоматично ще бъде прекратено след 1 час.': 'You can refuse the payment in ecoints or it will be automatically ended after 1 hour.',
  'Отказа плащане с ecoints': 'You refused a payment with ecoints',
  'Плащането с ecoints не е валидно': 'The payment with ecoints is not valid.',
  'Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш с ecoints с известие или QR код?': 'Your choice for future payments is in cash. Do you wish to change and to pay with ecoints with a notification or a QR code?',
  'Твоят избор за бъдещи плащания е по банков път. Желаеш ли да го промениш и плащаш с ecoints с известие или QR код?': 'Your choice for future payments is by bank transfer. Do you wish to change and to pay with ecoints with a notification or a QR code?',
  'Избери ecoints за бъдещи плащания с известие или QR код.': 'Choose ecoints for future payments by notification or QR code.',
  'Няма дължими суми по пратка.': 'There are no due amounts for a shipment.',
  'Документ за плащане': 'Payment document',
  'Общa дължима сума:': 'Total amount due:',
  'при плащане': 'when paying',
  'с ecoints': 'with ecoints',
  'по банков път': 'by bank transfer',
  'Български': 'Bulgarian',
  'Английски': 'English',
  'Език': 'Language',
  'Успешно заяви плащане с ecoints': 'You successfully ordered a payment with ecoints',
  'Клиентски номер': 'Client number',
  'Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш с клиентски номер?': 'Your choice for future payments is in cash. Do you want to change it and pay with a client number?',
  'С клиентски номер': 'With a client number',
  'Успешно заяви плащане с клиентски номер': 'Payment with a client number successfully requested',
  'Запази шаблон': 'Save template',
}

const enLang = (key: string) => en[key]

export default enLang