import { css, Global } from '@emotion/react'
import React from 'react'

const styles = css`
  html,
  body {
    background: #F5F5F5;
  }
`

const PaymentGlobalStyle = () => {
  return (
    <Global styles={styles} />
  )
}

export default PaymentGlobalStyle
