import { Button, ProcessOutcomeMessage, TertiaryButton, TextLink, ToggleGroup, styled } from "econt-design-system"
import { useCallback, useEffect, useState } from "react";
import { usePayment } from "../context/PaymentContext";
import client from "../utils/client";
import getInstructionLink from "../utils/getInstructionLink";
import { useSearchParams } from "react-router-dom";
import { useT } from "../translations/useTranslations";
import PackageLink from "../components/PackageLink";
import { useLanguage } from "../context/LanguageContext";
import { SaveTemplateWrapper } from "./CashSuccessfulPayment";
import { MOBILE_APP_ECONT } from "../utils/constants";

const EcointsSuccessfulPayment = () => {
  const { loadings, callbackUrl, defaultMethod, payer } = usePayment()
  const [saveEcoints, setSaveEcoints] = useState<boolean>(false)
  const currentLoading = loadings?.[0]
  const [searchParams] = useSearchParams();
  const t = useT()
  const { lang } = useLanguage()

  const appSourceParam = searchParams.get('AppSource')
  const qrParam = searchParams.get('qr')
  const showTemplateParam = searchParams.get('show_template')
  const courierRequestUrl = searchParams.get('courier_request_url')

  const defMethod = defaultMethod?.[currentLoading?.client_id]?.[0]?.payment_method

  useEffect(() => {
    const paymentId = sessionStorage.getItem('e_p_id')
    if (paymentId) {
      sessionStorage.removeItem('e_p_id')
    }
    if (defMethod === 'ecoints') {
      setSaveEcoints(true)
    }
  }, [defMethod])

  const getHeadline = useCallback(() => {
    if (defMethod === 'cash') {
      return t("Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш с ecoints с известие или QR код?")
    }
    if (defMethod === 'borica') {
      return t("Твоят избор за бъдещи плащания е по банков път. Желаеш ли да го промениш и плащаш с ecoints с известие или QR код?")
    }
    if (defMethod === 'credit') {
      return t("Твоят избор за бъдещи плащания е с клиентски номер. Желаеш ли да го промениш и плащаш с ecoints с известие или QR код?")
    }
    return t('Избери ecoints за бъдещи плащания с известие или QR код.')
  }, [defMethod, t])

  const hasPackageFromAddress = loadings && loadings?.length > 0 ? loadings?.find(loading => {
    return loading.sender_delivery === 'door'
  }) : false

  return (
    <Container>
      <ProcessOutcomeMessage
        headline={qrParam ? t("Успешно заяви плащане с ecoints") : t("Успешно плати с ecoints")}
        text={qrParam ? null : <Description>{t('Ще получиш електронен касов бон на имейла си.')}</Description>}
      >
        <>
          {loadings && payer === 'sender' ? <PackageLink loadings={loadings} /> : null}
          {currentLoading?.instruction_link && appSourceParam?.toLowerCase?.() !== MOBILE_APP_ECONT ? (
            <OtherMessage>
              {t('Промени всяко условие на доставката с')} <TextLink text={t("услугата Разпореждане")} href={getInstructionLink(currentLoading)} />
            </OtherMessage>
          ) : null}
          {currentLoading?.client_id && defMethod !== 'ecoints' ? (
            <ToggleGroupWrapper>
              <ToggleGroup
                name="save-ecoints"
                headline={getHeadline()}
                imageComponent={<img src="/images/ecoints.svg" alt="ecoints" />}
                // assistiveText={<AssistiveText>Можеш да промениш избора си по всяко време от <TextLink text="профила си в Еконт." href="https://ee.econt.com/" onClick={(e) => {
                //   e.stopPropagation()
                // }} /></AssistiveText>}
                checked={saveEcoints}
                onClick={async () => {
                  const result = await client.post('/setClientDesiredPaymentMethod', {
                    id_client: currentLoading.client_id,
                    payment_method: saveEcoints ? 'none' : 'ecoints',
                    payment_side: payer,
                    lang,
                  })

                  if (result?.data?.result) {
                    window.dataLayer.push({
                      'event' : 'DefaultPaymentMethod',
                      'UX_Action' : 'successful',
                      'payment_method' : saveEcoints ? 'None' : 'Ecoints'
                    });
                    setSaveEcoints(!saveEcoints)
                  } else {
                    window.dataLayer.push({
                      'event' : 'DefaultPaymentMethod',
                      'UX_Action' : 'failed',
                      'payment_method' : 'Ecoints'
                    });
                  }
                  
                }}
              />
            </ToggleGroupWrapper>
          ) : null}
          {
            showTemplateParam ? (
              <SaveTemplateWrapper>
                <TertiaryButton text={t("Запази шаблон")} type="neutral" icon="save" iconPosition="right" onClick={() => {
                  window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                      type: 'saveTemplate',
                    }),
                  )
                }} />
              </SaveTemplateWrapper>
            ) : null
          }
          {hasPackageFromAddress ? (
            <Button text={t("Заяви куриер")} size="large" onClick={() => {
              if (appSourceParam?.toLowerCase?.() === MOBILE_APP_ECONT) {
                window.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    type: 'requestCourier',
                  }),
                )
              } else {
                window.location.href = courierRequestUrl || 'https://www.econt.com/services/order-courier'
              }
            }} />
          ) : null}
        </>
      </ProcessOutcomeMessage>

      <BottomWrapper>
        <div>
          <TertiaryButton icon="arrow_back" type="neutral" text={t("Към начало")} onClick={() => {
            if (appSourceParam?.toLowerCase?.() === MOBILE_APP_ECONT) {
              window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  type: 'backToHome',
                }),
              )
            } else {
              window.location.href = callbackUrl
            }
          }} />
        </div>
      </BottomWrapper>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 87px auto 24px;

  & > div {
    width: 100%;
  }

  @media screen and (max-width: 968px) {
    margin: 48px auto 24px;
    padding: 0 16px;
  }
`

const ToggleGroupWrapper = styled.div`
  text-align: left;
  margin-bottom: 32px;
  width: 100%;

  & > div {
    width: 100%;
  }
`

const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  text-align: center;
`

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  color: ${({theme}) => theme.colors.grey[60]};

  @media screen and (max-width: 735px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
`

const OtherMessage = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  color: ${({theme}) => theme.colors.grey[60]};
  margin-bottom: 32px;

  @media screen and (max-width: 735px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
`

const AssistiveText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #747373;
`

export default EcointsSuccessfulPayment