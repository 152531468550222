import { useLanguage } from "../context/LanguageContext"
import bgLang from "./bg"
import enLang from "./en"

const useTranslations = (lang = 'bg') => {
  if (lang === 'en') {
    return (key: string) => enLang(key) || key
  }
  return (key: string) => bgLang(key) || key

}

export const useT = () => {
  const { lang } = useLanguage()
  if (lang === 'en') {
    return (key: string) => {
      return enLang(key) || key
    }
  }
  return (key: string) => {
    return bgLang(key) || key
  }
}

export const useLink = () => {
  const { lang } = useLanguage()

  if (lang === 'en') {
    return '/en'
  }
  return ''
}

export default useTranslations