import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

const LanguageContext = createContext<{
  lang: string,
  setLang: (lang: string) => void
}>({
  lang: 'bg',
  setLang: () => {}
})

interface LanguageContextProviderProps {
}

const LanguageContextProvider = ({ children }: LanguageContextProviderProps & PropsWithChildren) => {
  const [lang, setLang] = useState<string>('bg')
  const location = useLocation()

  useEffect(() => {
    if (location.pathname?.startsWith('/en') && lang === 'bg') {
      setLang('en')
    }
    else if (!location.pathname?.startsWith('/en') && lang === 'en') {
      setLang('bg')
    }
  }, [location])

  return (
    <LanguageContext.Provider value={{
      lang,
      setLang
    }}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => {
  const context = useContext(LanguageContext)

  return context
}

export default LanguageContextProvider

