import { TextLink, styled } from "econt-design-system"
import { useT } from "../translations/useTranslations"
import { useSearchParams } from "react-router-dom"

interface PackageLinkProps {
  loadings: any[]
}

const PackageLink = ({ loadings }: PackageLinkProps) => {
  const t = useT()
  const [searchParams] = useSearchParams();
  
  const printMethodParam = searchParams.get('print_method')


  return (
    <PackageMessage>
      {loadings?.length > 1 ? (
        <TextLink onClick={e => {
          e.preventDefault()
          loadings.forEach((loading) => {
            const tenNine = loading?.pdf_urls?.[printMethodParam || ''] || loading?.pdf_urls?.["10x9"]
            const a4 = loading?.pdf_urls?.[printMethodParam || ''] || loading?.pdf_urls?.["A4"]

            if (tenNine) {
              window.open(tenNine)
            } else if (a4) {
              window.open(a4)
            }
          })
        }} external text={`${loadings.length} ${t('Създадени товарителници')}`} />
      ): null}
      {loadings?.length === 1 && loadings?.[0]?.num ? <TextLink text={`${t('Номер на пратка:')} ${loadings[0]?.num || ''}`} href={loadings[0]?.pdf_urls?.[printMethodParam || "10x9"] || loadings[0]?.pdf_urls?.[printMethodParam || "A4"]} external /> : null}
    </PackageMessage>
  )
}

const PackageMessage = styled.div`
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.4px;
  margin-top: 24px;
  margin-bottom: 8px;

  & svg {
    width: 15px;
    height: 15px;
  }

  @media screen and (max-width: 735px) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.32px;
    margin-bottom: 16px;
  }
`


export default PackageLink