import { Button, Checkbox, Divider, InlineMessage, RadioButton, Tag, TextInput, TextLink, styled } from "econt-design-system"
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { usePayment } from "../context/PaymentContext"
import client from "../utils/client"
import ConfirmNoEcointsModal from "./ConfirmNoEcointsModal"
import { useLink, useT } from "../translations/useTranslations"
import { useLanguage } from "../context/LanguageContext"
import { MOBILE_APP_ECONT } from "../utils/constants"

interface CreditOption {
  value: string
  label: string
}

const allNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

const PaymentMethods = () => {
  const { loadings, payer, packageNumbers, data, setData, callbackUrl, disableCash, disableCardPayments } = usePayment()
  const t = useT()
  const [openConfirmEcointsModal, setOpenConfirmEcointsModal] = useState<boolean>(false)
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [inputError, setInputError] = useState<string | null>(null)
  const isCheckingEcoints = useRef<boolean>(false)
  const langLink = useLink()
  const { lang } = useLanguage()
  const creditOptions = useRef<CreditOption[]>([])

  const [creditNumbers, setCreditNumbers] = useState<string[]>([])
  const [selectedCredit, setSelectedCredit] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [cardholderName, setCardholderName] = useState<string>('')
  const [payOnDelivery, setPayOnDelivery] = useState<boolean>(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const location = useLocation()
  const appSourceParam = searchParams.get('AppSource')
  const phoneNumberParam = searchParams.get('phone')

  const checkEcoints = async (clientPhone?: string) => {
    if (clientPhone) {
      isCheckingEcoints.current = true
      const result = await client.post('/checkRegisteredPhone', {
        phone_number: clientPhone,
        lang
      })
      isCheckingEcoints.current = false
      if (result?.data?.result) {
        setData({
          ...data,
          ecoints: true
        })
        return
      }
      setData({
        ...data,
        ecoints: false
      })
    }
  }

  useEffect(() => {
    if (loadings && loadings.length > 0) {
      const firstLoading = loadings[0]
      const phone = phoneNumberParam?.trim() || firstLoading?.client_phones || ''
      // const clientEmail = firstLoading?.client_email || ''

      const credits = loadings?.map(loading => loading?.keyword || '')?.filter(credit => credit !== '')

      setPhoneNumber(phone)
      setCreditNumbers(credits)
      // setEmail(clientEmail)
      
      if (credits.length > 0) {
        creditOptions.current = credits.map((credit) => {
          return {
            value: credit,
            label: credit
          }
        })
        setSelectedCredit(credits[0])
      }
    }
  }, [loadings])

  useEffect(() => {
    (async () => {
      const paymentId = sessionStorage.getItem('e_p_id')
      sessionStorage.removeItem('e_p_id')
      if (paymentId) {
        await client.post('/cancelPaymentRequest', {
          payment_id: paymentId,
          lang,
        })
      }
    })()
  }, [])

  const handleConfirm = async () => {
    if (isCheckingEcoints.current) {
      return
    }

    if (!data.paymentMethod) {
      return
    }

    if (data.paymentMethod === 'ecoints' && (!phoneNumber && !payOnDelivery)) {
      return
    }

    if (data.paymentMethod === 'ecoints' && !data.ecoints) {
      window.dataLayer.push({
        event : 'FunctionalityUsed',
        UX_Action : 'Error',
        UX_Element : 'Pop-up',
        Error : 'no-ecoints-on-phone'
      });
      
      setOpenConfirmEcointsModal(true)
      return
    }

    setIsConfirmLoading(true)

    if (data.paymentMethod === 'ecoints' && payOnDelivery) {
      const result = await client.post('/setQRPayOnSpot', {
        client_phone: phoneNumber,
        pay_side: payer,
        loading_nums: packageNumbers,
        lang
      })
      
      if (result?.data?.result) {
        window.dataLayer.push({
          'event' : 'PaymentStatus',
          'UX_Action' : 'successful',
          'payment_method' : 'QR'
        });
        
        navigate(`${langLink}/ecoints-successful-payment${location.search}&qr=true`)
        setIsConfirmLoading(false)
      } else {
        if (result?.data?.error?.additional_data?.message) {
          setError(result?.data?.error?.additional_data?.message)
        } else {
          setError(t('Изникна проблем, моля опитайте пак'))
        }
        window.dataLayer.push({
          'event' : 'PaymentStatus',
          'UX_Action' : 'failed',
          'payment_method' : 'QR'
        });
        setIsConfirmLoading(false)
      }

      return
    }
    if (data.paymentMethod === 'borica') {
      const result = await client.post('/generateBankPayment', {
        pay_side: payer,
        loading_nums: packageNumbers,
        customer_email: email,
        success_url: `${window.location.origin}${langLink}/bank-successful-payment${location.search}`,
        fail_url: `${window.location.origin}${langLink}/bank-successful-payment${location.search}&fail=true`,
        payment_key: location.search?.split('?')?.[1]?.replace('%7D', ''),
        lang,
        cardholder_name: cardholderName?.trim()
      })

      if (result?.data?.result?.url) {
        setIsConfirmLoading(false)
        window.location.href = result?.data?.result?.url
        return
      }
      if (result?.data?.error?.additional_data?.message) {
        setError(result?.data?.error?.additional_data?.message)
      } else {
        setError(t('Изникна проблем, моля опитайте пак'))
      }
      window.dataLayer.push({
        'event' : 'PaymentStatus',
        'UX_Action' : 'failed',
        'payment_method' : 'Borica'
      });
      setIsConfirmLoading(false)
      return
    }
    if (data.paymentMethod === 'credit') {
      const result = await client.post('/setCreditPayment', {
        payment_side: payer,
        loading_nums: packageNumbers,
        keyword: selectedCredit,
        lang
      })

      if (result?.data?.result) {
        setIsConfirmLoading(false)
        navigate(`${langLink}/credit-successful-payment${location.search}&credit=${selectedCredit}`)
        return
      }
      if (result?.data?.error?.additional_data?.message) {
        setError(result?.data?.error?.additional_data?.message)
      } else {
        setError(t('Изникна проблем, моля опитайте пак'))
      }
      window.dataLayer.push({
        'event' : 'PaymentStatus',
        'UX_Action' : 'failed',
        'payment_method' : 'Credit'
      });
      setIsConfirmLoading(false)
      return
    }
    if (data.paymentMethod === 'cash') {
      window.dataLayer.push({
        'event' : 'PaymentStatus',
        'UX_Action' : 'successful',
        'payment_method' : 'Cash'
      });
      navigate(`${langLink}/cash-successful-payment${location.search}`)
      setIsConfirmLoading(false)
      return
    }

    const result = await client.post('/generatePushPayment', {
      client_phone: phoneNumber,
      pay_side: payer,
      loading_nums: packageNumbers,
      payment_key: location.search?.split('?')?.[1]?.replace('%7D', ''),
      lang,
    })
    const paymentId = result?.data?.result?.payment_id
    
    if (paymentId) {
      window.dataLayer.push({
        'event' : 'PaymentStatus',
        'UX_Action' : 'pending',
        'payment_method' : 'Ecoints'
      });
      navigate(`${langLink}/ecoints-payment-request${location.search}&id=${paymentId}&phone=${phoneNumber}`)
      setIsConfirmLoading(false)
    } else {
      if (result?.data?.error?.additional_data?.message) {
        setError(result?.data?.error?.additional_data?.message)
      } else {
        setError(t('Изникна проблем, моля опитайте пак'))
      }
      window.dataLayer.push({
        'event' : 'PaymentStatus',
        'UX_Action' : 'failed',
        'payment_method' : 'Ecoints'
      });
      setIsConfirmLoading(false)
    }
  }

  const disableConfirm = () => {
    if (!data.paymentMethod || (data.paymentMethod === 'ecoints' && (!phoneNumber && !payOnDelivery))) {
      return true
    }

    if (data.paymentMethod === 'ecoints' && !payOnDelivery && (phoneNumber.length < 9 || phoneNumber.length > 14)) {
      return true
    }

    if (data.paymentMethod === 'borica' && (!email || inputError)) {
      return true
    }

    if (data.paymentMethod === 'cash' && disableCash) {
      return true
    }

    if (data.paymentMethod === 'ecoints' && disableCardPayments) {
      return true
    }

    if (data.paymentMethod === 'borica' && disableCardPayments) {
      return true
    }

    return false
  }

  const hasNPService = loadings?.find(loading => {
    return loading.services?.find((s: any) => {
      return s.description === 'Сума на наложен платеж' || s.description === 'Cash on delivery amount'
    })
  })

  return (
    <>
      <Title>{t('Избери как ще платиш')}</Title>
      <PaymentChoicesWrapper>
        {!disableCardPayments ? (
          <Column>
            <Row>
              <RadioButtonWrapper>
                <RadioButton
                  value={'ecoints'} 
                  checked={data.paymentMethod === 'ecoints'} 
                  name="paymentMethod" 
                  onChange={() => {
                    setData({
                      ...data,
                      paymentMethod: 'ecoints'
                    })
                    setError(null)
                  }} 
                />
                <EcointsLogo onClick={() => {
                  setData({
                    ...data,
                    paymentMethod: 'ecoints'
                  })
                  setError(null)
                }} src={'/images/ecoints.svg'} alt="ecoints" width='90' height='30' />
              </RadioButtonWrapper>

              <Tag text={t("Предпочитано")} color="green" size="small" />
            </Row>
            {data.paymentMethod === 'ecoints' ? (
              <MoreDetails>
                {!payOnDelivery ? (
                  <TextInput
                    value={phoneNumber}
                    onChange={(e) => {
                      const value = e.target.value
                      const letters = value.split('')
                      const hasWrongSymbol = letters.find(letter => {
                        return !(allNumbers.includes(parseInt(letter)) || letter === '+' || letter === ' ')
                      })
                      
                      if (hasWrongSymbol) {
                        return
                      }
                      const trimmedValue = e.target.value?.trim()
                      setPhoneNumber(trimmedValue)
                      setError(null)
                      if (trimmedValue.length > 9 && trimmedValue.length < 14) {
                        checkEcoints(trimmedValue)
                      }
                    }}
                    label={t("Телефонен номер за потвърждение на плащане")}
                    type="tel"
                    fullWidth
                  />
                ) : null}
                <Checkbox checked={payOnDelivery} onChange={e => setPayOnDelivery(e.target.checked)} name='pay-on-delivery' label={t("Ще платя на място чрез QR код")} />

              </MoreDetails>
            ) : null}
          </Column>
        ): null}
        {!disableCardPayments ? (
          <Column>
            <Row>
              <RadioButtonWrapper>
                <RadioButton
                  value={'borica'} 
                  checked={data.paymentMethod === 'borica'} 
                  name="paymentMethod" 
                  onChange={() => {
                    setData({
                      ...data,
                      paymentMethod: 'borica'
                    })
                    setError(null)
                  }} 
                  label={t("Банкова карта")}
                />
              </RadioButtonWrapper>
            </Row>
            {data.paymentMethod === 'borica' ? (
              <MoreDetails>
                <TextInput
                  value={cardholderName}
                  error={inputError || undefined}
                  autoComplete="name"
                  name="name"
                  onChange={(e) => {
                    const value = e.target.value
                    setCardholderName(value)
                    if (error) {
                      setError(null)
                    }

                    if (!value.match(/^[A-Za-z\s]+$/)) {
                      setInputError(t('Въведените символите трябва да са само на латиница [a-Z]'))
                    } else if (inputError) {
                      setInputError(null)
                    }
                  }}
                  label={t("Въведи имена на картодържателя по банкова карта на латиница")}
                  onBlur={() => {
                    const cardName = cardholderName?.trim()
                    const words = cardName?.split(' ') || []
                    if (words.length > 2) {
                      setInputError(t('Въведи две имена на картодържателя по банкова карта на латиница'))
                    } else {
                      if (!cardName.match(/^[A-Za-z]+\s+[A-Za-z]+$/)) {
                        setInputError(t('Въведеното име е непълно.'))
                      } else if (inputError) {
                        setInputError(null)
                      }
                    }
                  }}
                  type="text"
                  fullWidth
                />
                <TextInput
                  value={email}
                  autoComplete="email"
                  name="email"
                  onChange={(e) => {
                    const value = e.target.value
                    setEmail(value)
                    if (error) {
                      setError(null)
                    }
                  }}
                  label={t("Въведи имейл, на който да получиш своя касов бон")}
                  type="email"
                  fullWidth
                />
              </MoreDetails>
            ) : null}
          </Column>
        ) : null}
        {!disableCash ? (
          <Row>
            <RadioButton
              value={'cash'} 
              checked={data.paymentMethod === 'cash'} 
              name="paymentMethod" 
              onChange={() => {
                setData({
                  ...data,
                  paymentMethod: 'cash'
                })
                setError(null)
              }} 
              label={t("В брой")}
            />
          </Row>
        ) : null}
        {!hasNPService && creditNumbers.length > 0 && creditNumbers.length <= 1 ? (
          <Column>
            <Row>
              <RadioButtonWrapper>
                <RadioButton
                  value={'credit'} 
                  checked={data.paymentMethod === 'credit'} 
                  name="paymentMethod"
                  assistiveText={creditNumbers.length === 1 ? selectedCredit : ''}
                  onChange={() => {
                    setData({
                      ...data,
                      paymentMethod: 'credit'
                    })
                    setError(null)
                  }} 
                  label={t("Клиентски номер")}
                />
              </RadioButtonWrapper>
            </Row>
          </Column>
        ) : null}
      </PaymentChoicesWrapper>
      <Divider />
      <ButtonsRow>
        <TextLink text={t("Назад")} onClick={() => {
          if (appSourceParam?.toLowerCase?.() === MOBILE_APP_ECONT) {
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'backBtn',
              }),
            )
          } else {
            window.location.href = callbackUrl
          }
        }} />
        <Button
          text={t("Потвърди")}
          size="large"
          disabled={disableConfirm()}
          onClick={handleConfirm}
          loading={isConfirmLoading}
        />
      </ButtonsRow>
      <MobileButtonsRow>
        <TextLink text={t("Назад")} onClick={() => {
          if (appSourceParam?.toLowerCase?.() === MOBILE_APP_ECONT) {
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'backBtn',
              }),
            )
          } else {
            window.location.href = callbackUrl
          }
        }} />
        <Button
          text={t("Потвърди")}
          size="medium"
          disabled={disableConfirm()}
          onClick={handleConfirm}
          loading={isConfirmLoading}
        />
      </MobileButtonsRow>
      {error ? (
        <ErrorWrapper>
          <InlineMessage type="error" text={error} />
        </ErrorWrapper>
      ) : null}
      <ConfirmNoEcointsModal open={openConfirmEcointsModal} setOpen={setOpenConfirmEcointsModal} />
    </>
  )
}

const PaymentChoicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`
const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 24px;

  @media screen and (max-width: 968px) {
    display: none;
  }
`

const MobileButtonsRow = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 24px;

  @media screen and (max-width: 968px) {
    display: flex;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 13px;
`

const MoreDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding-left: 42px;
`

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const EcointsLogo = styled.img`
  width: 90px;
  cursor: pointer;
`

const Title = styled.h2`
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  margin-bottom: 32px;
  color: ${({theme}) => theme.colors.grey[70]};

  @media screen and (max-width: 735px) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.32px;
  }
`

const ErrorWrapper = styled.div`
  margin-top: 12px;
`

export default PaymentMethods