import { TertiaryButton, styled } from "econt-design-system"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SignalModal from "../components/SignalModal";
import { useEffect, useState } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import client from "../utils/client";
import { usePayment } from "../context/PaymentContext";
import { useLink, useT } from "../translations/useTranslations";
import { useLanguage } from "../context/LanguageContext";

const EcointsPaymentRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { payer, packageNumbers, loadings } = usePayment()
  const [paymentStatus, setPaymentStatus] = useState<'rejected' | 'expired' | 'completed' | null>(null)
  const [isNotificationLoading, setIsNotificationLoading] = useState<boolean>(false)
  const [openSignalModal, setOpenSignalModal] = useState<boolean>(false)
  const [sendNotificationAgain, setSendNotificationAgain] = useState<boolean>(false)
  const [showSignalMessage, setShowSignalMessage] = useState<boolean>(false)
  const [searchParams] = useSearchParams();
  const t = useT()
  const langLink = useLink()
  const { lang } = useLanguage()
  const paymentIdParam = searchParams.get('id')
  const phoneNumber = searchParams.get('phone')
  const appSourceParam = searchParams.get('AppSource')
  const currentLoading = loadings?.[0]

  useEffect(() => {
    const paymentId = sessionStorage.getItem('e_p_id')
    if (paymentId) {
      sessionStorage.removeItem('e_p_id')
    }
    if (!paymentIdParam) {
      navigate(`${langLink}/${location.search}`)
    }

    return () => {
      if (paymentIdParam) {
        sessionStorage.setItem("e_p_id", paymentIdParam);
      }
    }
  }, [])

  useEffect(() => {
    let interval: NodeJS.Timer | null = null
    if (paymentIdParam) {
      interval = setInterval(async () => {
        const result = await client.post('/checkPaymentStatus', {
          payment_id: paymentIdParam,
          lang,
        })
        // rejected
        if (result?.data?.result?.status === 'rejected' || result?.data?.result?.status === 'canceled') {
          window.dataLayer.push({
            'event' : 'PaymentStatus',
            'UX_Action' : result?.data?.result?.status,
            'payment_method' : 'Ecoints'
          });
          setPaymentStatus('rejected')
          if (interval) {
            clearInterval(interval)
          }
        }
        if (result?.data?.result?.status === 'completed') {
          if (interval) {
            clearInterval(interval)
          }
          window.dataLayer.push({
            'event' : 'PaymentStatus',
            'UX_Action' : 'successful',
            'payment_method' : 'Ecoints'
          });
          navigate(`${langLink}/ecoints-successful-payment${location.search}`)
        }
        if (result?.data?.result?.status === 'expired') {
          setPaymentStatus('expired')
          window.dataLayer.push({
            'event' : 'PaymentStatus',
            'UX_Action' : 'expired',
            'payment_method' : 'Ecoints'
          });

          if (interval) {
            clearInterval(interval)
          }
        }

      }, 4000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  const handleClick = async () => {
    setIsNotificationLoading(true)
    if (sendNotificationAgain) {
      const result = await client.post('/generatePushPayment', {
        client_phone: phoneNumber || currentLoading?.client_phones,
        pay_side: payer,
        loading_nums: packageNumbers,
        payment_id: paymentIdParam,
        payment_key: location.search?.split('?')?.[1],
        lang,
      })
      setIsNotificationLoading(false)
      setShowSignalMessage(true)
      return
    }
    const result = await client.post('/generatePushPayment', {
      client_phone: phoneNumber || currentLoading?.client_phones,
      pay_side: payer,
      loading_nums: packageNumbers,
      payment_id: paymentIdParam,
      payment_key: location.search?.split('?')?.[1],
      lang
    })

    setIsNotificationLoading(false)
    setSendNotificationAgain(true)
  }

  if (!paymentIdParam) {
    return null
  }

  return (
    <Container>
      {paymentStatus === null ? (
        <>
          <Wrapper>
            <Title>{sendNotificationAgain ? <>{t('Изпратена е нотификация')}<br/> {t('Потвърди трансакцията в приложение ecoints.')}</> : <>{t('Успешно заяви плащане в ecoints')}<br/> {t('Нужно е само да го потвърдиш.')}</>}</Title>
            <MissingNotification>{showSignalMessage ? t("Не получаваш известие?") : t("Липсва известие на екрана ти?")}</MissingNotification>
            {showSignalMessage ? (
              <MissingNotification>{t('Можеш да подадеш сигнал за нередност.')}</MissingNotification>
            ): (
              <>
                <TertiaryWrapper>
                  <TertiaryButton icon="arrow_forward" loading={isNotificationLoading} iconPosition="right" text={t("Изпрати известието отново")} onClick={handleClick} />
                </TertiaryWrapper>
                <MobileTertiaryWrapper>
                  <TertiaryButton icon="arrow_forward" loading={isNotificationLoading} iconPosition="right" text={t("Опитай отново")} onClick={handleClick} />
                </MobileTertiaryWrapper>
              </>
            )}
            <Player
              loop={true}
              autoplay
              controls={false}
              src={'/animations/notification.json'}
            />
          </Wrapper>
          <BottomWrapper>
            <div>
              <TertiaryButton icon="arrow_forward" iconPosition="right" type="neutral" text={t("Ще платя по друг начин")} onClick={() => {
                navigate(`${langLink}/${location.search}`)
              }} />
              <TertiaryButton icon="error" iconPosition="right" type="neutral" text={t("Изпрати сигнал")} onClick={() => {
                setOpenSignalModal(true)
              }} />
            </div>
            <Clarification>
              {t('Може да откажеш плащането в ecoints или автоматично ще бъде прекратено след 1 час.')}
            </Clarification>
          </BottomWrapper>
          <SignalModal open={openSignalModal} setOpen={setOpenSignalModal} phoneNumber={phoneNumber || currentLoading?.client_phones || ''} />
        </>
      ) : null}
      {paymentStatus === 'rejected' ? (
        <RejectedContainer>
          <RejectedWrapper>
            <RejectedMsg>{t('Отказа плащане с ecoints')}</RejectedMsg>
            <TertiaryButton text={t("Избери друг начин на плащане")} type="neutral" icon="arrow_forward" iconPosition="right" onClick={() => {
              navigate(`${langLink}/${location.search}&phone=${phoneNumber}`)
            }} />
          </RejectedWrapper>
        </RejectedContainer>
      ) : null}
      {paymentStatus === 'expired' ? (
        <RejectedContainer>
          <RejectedWrapper>
            <RejectedMsg>{t('Плащането с ecoints не е валидно')}</RejectedMsg>
            <TertiaryButton text={t("Избери друг начин на плащане")} type="neutral" icon="arrow_forward" iconPosition="right" onClick={() => {
              navigate(`${langLink}/${location.search}&phone=${phoneNumber}`)
            }} />
          </RejectedWrapper>
        </RejectedContainer>
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 87px auto 24px;

  @media screen and (max-width: 968px) {
    margin: 48px auto 24px;
    padding: 0 16px;
  }
`

const Wrapper = styled.div`
  background: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius.l};
  padding: 32px 32px 225px;
  position: relative;
  text-align: center;
  overflow: hidden;

  & #lottie {
    position: absolute;
    bottom: -30px;
    right: 93px;
    left: 93px;
  }

  @media screen and (max-width: 968px) {
    padding: 16px 16px 170px;

    & #lottie {
      position: absolute;
      bottom: -10px;
      right: 16px;
      left: 16px;
      padding: 0 130px;
    }
  }

  @media screen and (max-width: 650px) {

    & #lottie {
      padding: 0 110px;
    }
  }

  @media screen and (max-width: 586px) {

    & #lottie {
      padding: 0 80px;
    }
  }

  @media screen and (max-width: 520px) {

    & #lottie {
      padding: 0 50px;
    }
  }

  @media screen and (max-width: 415px) {

    & #lottie {
      padding: 0;
    }
  }
`

const RejectedContainer = styled.div`
  background: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius.l};
  padding: 32px;
  text-align: center;
  width: 100%;
`

const RejectedWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
`

const RejectedMsg = styled.div`
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
  color: ${({theme}) => theme.colors.red[30]};
`

const Title = styled.h2`
  font-size: ${({theme}) => theme.typography.heading.l.fontSize};
  line-height: ${({theme}) => theme.typography.heading.l.lineHeight};
  color: ${({theme}) => theme.colors.grey[70]};
  margin: 0;
  margin-bottom: 24px;

  @media screen and (max-width: 968px) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 32px;
  }
`

const TertiaryWrapper = styled.div`
  display: block;

  @media screen and (max-width: 968px) {
    display: none;
  }
`
const MobileTertiaryWrapper = styled.div`
  display: none;

  @media screen and (max-width: 968px) {
    display: block;
  }
`

const MissingNotification = styled.div`
  color: ${({theme}) => theme.colors.grey[60]};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  margin-bottom: 4px;
  
  @media screen and (max-width: 968px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
`

const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  text-align: center;

  @media screen and (max-width: 968px) {
    padding: 24px 16px;
  }
`

const Clarification = styled.div`
  color: ${({theme}) => theme.colors.grey[50]};
  font-size: 13px;
  line-height: 16px;
  padding: 0 32px;

  @media screen and (max-width: 968px) {
    padding: 0 16px;
  }
`

export default EcointsPaymentRequest