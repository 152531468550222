import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import client from "../utils/client"
import { useLanguage } from "./LanguageContext";

interface Data {
  paymentMethod?: string,
  packageNumbers?: string | null,
  loadings?: any[],
  totals?: any
  payer?: string | null,
  ecoints?: boolean | null
}

const PaymentContext = createContext<{
  packageNumbers?: string | null
  payer?: string | null
  loadings?: any[]
  totals: any,
  ecoints?: boolean | null
  setData: (obj: Data) => void
  data: Data
  isLoading: boolean
  callbackUrl: string
  disableCash: boolean
  disableCardPayments: boolean
  defaultMethod: any
}>({
  packageNumbers: null,
  payer: null,
  loadings: [],
  totals: null,
  ecoints: false,
  setData: () => null,
  data: {},
  isLoading: true,
  disableCash: false,
  disableCardPayments: false,
  callbackUrl: '',
  defaultMethod: {},
})

interface PaymentContextProviderProps {
}

const PaymentContextProvider = ({ children }: PaymentContextProviderProps & PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [defaultMethod, setDefaultMethod] = useState<any>({})
  const [searchParams] = useSearchParams();
  const packageNumbers = searchParams.get('packages')
  const payer = searchParams.get('payment_side')
  const anonParam = searchParams.get('anon')
  const callbackUrl = searchParams.get('callback_url') || ''
  const disableCash = searchParams.get('cash') === 'off'
  const disableCardPayments = searchParams.get('card') === 'off'
  const phoneNumberParam = searchParams.get('phone')
  const { lang } = useLanguage()

  const [data, setData] = useState<Data>({
    paymentMethod: undefined,
    packageNumbers: packageNumbers,
    totals: null,
    loadings: [],
    payer: payer,
    ecoints: null,
  })

  useEffect(() => {
    if (data.packageNumbers && data.payer) {
      (async () => {
        const body: any = {
          loading_nums: packageNumbers,
          payment_side: payer,
          lang,
        }

        if (anonParam) {
          body.anon = anonParam
        }

        const result = await client.post('/getPaymentInfo', body)

        if (result?.data?.error) {
          window.location.href = 'https://www.ecoints.com/'
          return
        }

        const loads = result?.data?.result?.loadings || []
        const totals = result?.data?.result?.totals
        const firstLoading = loads?.[0]
        const clientIds = loads?.map((load: any) => load.client_id)?.join(', ')
        const clientPhone = phoneNumberParam?.trim() || firstLoading?.client_phones || ''
        let defMethods: any = {}

        if (clientIds) {
          const defaultMethods = await client.post('/getClientDesiredPaymentMethod', {
            id_client: clientIds,
            lang
          })

          if (defaultMethods?.data?.result?.clients) {
            defMethods = defaultMethods?.data?.result?.clients
            setDefaultMethod(defMethods)
          }
        }

        if (clientPhone) {
          const ecointsResult = await client.post('/checkRegisteredPhone', {
            phone_number: clientPhone,
            lang
          })

          if (ecointsResult?.data?.result) {
            setData({
              ...data,
              totals,
              loadings: loads,
              paymentMethod: defMethods?.[firstLoading?.client_id]?.[0]?.payment_method || null,
              ecoints: true,
            })
            setIsLoading(false)
            return
          } else {

            setData({
              ...data,
              totals,
              loadings: loads,
              paymentMethod: defMethods?.[firstLoading?.client_id]?.[0]?.payment_method || null,
              ecoints: false,
            })
            setIsLoading(false)
            return
          }
        }
        setData({
          ...data,
          totals,
          loadings: loads,
          paymentMethod: defMethods?.[firstLoading?.client_id]?.[0]?.payment_method || null,
        })
        setIsLoading(false)
      })()
    } else {
      window.location.href = 'https://www.ecoints.com/'
    }
  }, [lang])

  return (
    <PaymentContext.Provider value={{
      packageNumbers: data.packageNumbers,
      payer: data.payer,
      loadings: data.loadings,
      ecoints: data.ecoints,
      totals: data.totals,
      setData,
      data,
      isLoading,
      callbackUrl,
      disableCash,
      defaultMethod,
      disableCardPayments,
    }}>
      {children}
    </PaymentContext.Provider>
  )
}

export const usePayment = () => {
  const context = useContext(PaymentContext)

  return context
}

export default PaymentContextProvider

