import { Button, Icon, ProcessOutcomeMessage, TertiaryButton, TextLink, ToggleGroup, styled } from "econt-design-system"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { usePayment } from "../context/PaymentContext";
import client from "../utils/client";
import getInstructionLink from "../utils/getInstructionLink";
import { useLink, useT } from "../translations/useTranslations";
import PackageLink from "../components/PackageLink";
import { useLanguage } from "../context/LanguageContext";
import { SaveTemplateWrapper } from "./CashSuccessfulPayment";
import { MOBILE_APP_ECONT } from "../utils/constants";

const BankSuccessfulPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams()
  const fail = searchParams.get('fail')
  const appSourceParam = searchParams.get('AppSource')
  const t = useT()
  const langLink = useLink()
  const { lang } = useLanguage()
  const { loadings, payer, callbackUrl, defaultMethod } = usePayment()
  const [saveBank, setSaveBank] = useState<boolean>(false)
  const currentLoading = loadings?.[0]
  const defMethod = defaultMethod?.[currentLoading?.client_id]?.[0]?.payment_method
  const showTemplateParam = searchParams.get('show_template')
  const courierRequestUrl = searchParams.get('courier_request_url')

  useEffect(() => {
    if (defMethod === 'borica') {
      setSaveBank(true)
    }
  }, [defMethod])

  const getHeadline = useCallback(() => {
    if (defMethod === 'cash') {
      return t("Твоят избор за бъдещи плащания е в брой. Желаеш ли да го промениш и плащаш по банков път?")
    }
    if (defMethod === 'ecoints') {
      return t("Твоят избор за бъдещи плащания е ecoints. Желаеш ли да го промениш и плащаш по банков път?")
    }
    if (defMethod === 'credit') {
      return t("Твоят избор за бъдещи плащания е с клиентски номер. Желаеш ли да го промениш и плащаш по банков път?")
    }
    return t('Избери по банков път за бъдещи плащания.')
  }, [defMethod, t])

  const hasPackageFromAddress = loadings && loadings?.length > 0 ? loadings?.find(loading => {
    return loading.sender_delivery === 'door'
  }) : false

  if (fail === 'true') {
    return (
      <Container>
        <RejectedContainer>
          <RejectedWrapper>
            <RejectedMsg>{t('Плащането е неуспешно')}</RejectedMsg>
            <TertiaryButton text={t("Избери друг начин на плащане")} type="neutral" icon="arrow_forward" iconPosition="right" onClick={() => {
              navigate(`${langLink}/${location.search}`)
            }} />
          </RejectedWrapper>
        </RejectedContainer>
      </Container>
    )
  }

  return (
    <Container>
      <ProcessOutcomeMessage headline={t("Успешно плати с банкова карта")} text={<Description>{t('Ще получиш електронен касов бон на имейла си.')}</Description>}>
        <>
          {loadings && payer === 'sender' ? <PackageLink loadings={loadings} /> : null}
          {currentLoading?.instruction_link && appSourceParam?.toLowerCase?.() !== MOBILE_APP_ECONT ? (
            <OtherMessage>
              {`${t('Промени всяко условие на доставката с')}`} <TextLink text={t("услугата Разпореждане")} href={getInstructionLink(currentLoading)} />
            </OtherMessage>
          ) : null}
          {currentLoading?.client_id && defMethod !== 'borica' ? (
            <ToggleGroupWrapper>
              <ToggleGroup
                name="save-ecoints"
                headline={getHeadline()}
                imageComponent={(
                  <BankLabelWrapper>
                    <StyledIcon name="wallet" />
                    <Label>{t('Банкова карта')}</Label>
                  </BankLabelWrapper>
                )}
                // assistiveText={<AssistiveText>Можеш да промениш избора си по всяко време от <TextLink text="профила си в Еконт." href="https://ee.econt.com/" onClick={(e) => {
                //   e.stopPropagation()
                // }} /></AssistiveText>}
                checked={saveBank}
                onClick={async () => {
                  const result = await client.post('/setClientDesiredPaymentMethod', {
                    id_client: currentLoading.client_id,
                    payment_method: saveBank ? 'none' : 'borica',
                    payment_side: payer,
                    lang,
                  })

                  if (result?.data?.result) {
                    window.dataLayer.push({
                      'event' : 'DefaultPaymentMethod',
                      'UX_Action' : 'successful',
                      'payment_method' : saveBank ? 'None' : 'Borica'
                    });
                    setSaveBank(!saveBank)
                  } else {
                    window.dataLayer.push({
                      'event' : 'DefaultPaymentMethod',
                      'UX_Action' : 'failed',
                      'payment_method' : 'Borica'
                    });
                  }
                }}
              />
            </ToggleGroupWrapper>
          ): null}
          {
            showTemplateParam ? (
              <SaveTemplateWrapper>
                <TertiaryButton text={t("Запази шаблон")} onClick={() => {
                  window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                      type: 'saveTemplate',
                    }),
                  )
                }} />
              </SaveTemplateWrapper>
            ) : null
          }
          {
            hasPackageFromAddress ? (
              <Button text={t("Заяви куриер")} size="large" onClick={() => {
                if (appSourceParam?.toLowerCase?.() === MOBILE_APP_ECONT) {
                  window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                      type: 'requestCourier',
                    }),
                  )
                } else {
                  window.location.href = courierRequestUrl || 'https://www.econt.com/services/order-courier'
                }
              }} />
            ) : null
          }
        </>
      </ProcessOutcomeMessage>

      <BottomWrapper>
        <div>
          <TertiaryButton icon="arrow_back" type="neutral" text={t("Към начало")} onClick={() => {
            if (appSourceParam?.toLowerCase?.() === MOBILE_APP_ECONT) {
              window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  type: 'backToHome',
                }),
              )
            } else {
              window.location.href = callbackUrl
            }
          }} />
        </div>
      </BottomWrapper>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 87px auto 24px;

  & > div {
    width: 100%;
  }

  @media screen and (max-width: 968px) {
    margin: 48px auto 24px;
    padding: 0 16px;
  }
`

const ToggleGroupWrapper = styled.div`
  text-align: left;
  margin-bottom: 32px;
  width: 100%;
  
  & > div {
    width: 100%;
  }

`

export const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  text-align: center;
`

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  color: ${({theme}) => theme.colors.grey[60]};

  @media screen and (max-width: 735px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
`

const OtherMessage = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  color: ${({theme}) => theme.colors.grey[60]};
  margin-bottom: 32px;

  @media screen and (max-width: 735px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.28px;
  }
`

const BankLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

const StyledIcon = styled(Icon)`
  fill: ${({theme}) => theme.colors.grey[70]};
`

const Label = styled.span`
  font-size: 18px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.grey[70]};

  @media screen and (max-width: 735px) {
    font-size: 15px;
  }
`

const RejectedContainer = styled.div`
  background: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius.l};
  padding: 32px;
  text-align: center;
  width: 100%;
`

const RejectedWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
`

const RejectedMsg = styled.div`
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
  color: ${({theme}) => theme.colors.red[30]};
`

const AssistiveText = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #747373;
`


export default BankSuccessfulPayment